<template>
    <div>
      <b-card>
        <phases-row-timeline-carbon-farm :carbonFarmInformation="this.carbonFarmInformation"></phases-row-timeline-carbon-farm>
      </b-card>
      <b-row class="match-height">
        
        <b-col md="4" lg="4">        
            <b-card>   
             <b-img :src="imgUrl" fluid style="max-height:300px; width: 100%;" />
            </b-card>
          </b-col> 
        <b-col md="8" lg="8">        
          <b-card>   
            <b-row class="mb-0">
              <b-col>
                <h3>
                  {{carbonFarmInformation.farmCode}}: {{carbonFarmInformation.farmName}}
                </h3>                                   
              </b-col>
            </b-row>

            <b-row class="mt-1">              
              <b-col md="6" lg="6">                            
                <app-timeline>
                  <app-timeline-item variant="warning" icon="MapPinIcon" style="margin-left:10px; padding:4px 20px">
                    <div @click="openFarmLocationInMap" class="sosty-pointer">
                      <h6><strong><u>Ver Ubicación</u></strong></h6>                     
                    </div>
                  </app-timeline-item>
                  <app-timeline-item variant="success" icon="UserIcon" style="margin-left:10px; padding:4px 20px">
                    <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
                      <h6 style="opacity:0.6">Productor: {{carbonFarmInformation.firstName}} {{carbonFarmInformation.lastName}} </h6>
                    </div>
                  </app-timeline-item>
                  <app-timeline-item v-if="carbonFarmInformation.initDate" variant="warning" icon="CalendarIcon" style="margin-left:10px;padding:4px 20px">
                    <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
                      <h6 style="opacity:0.6">Fecha Inicio: {{(carbonFarmInformation.initDate).substring(0, 10)}}</h6>
                    </div>
                  </app-timeline-item>
                  <!-- <app-timeline-item v-if="carbonFarmInformation.transitionDate" variant="success" icon="CalendarIcon" style="margin-left:10px;padding:4px 20px">
                    <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
                      <h6 style="opacity:0.6">Fecha Transición: {{(carbonFarmInformation.transitionDate).substring(0, 10)}} </h6>                      
                    </div>                    
                  </app-timeline-item> -->
                  <app-timeline-item variant="success" icon="UserIcon" style="margin-left:10px;padding:4px 20px">
                    <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
                      <h6 style="opacity:0.6">Asesor: {{carbonFarmInformation.advisor}} </h6>                      
                    </div>
                  </app-timeline-item>                            
                </app-timeline>
              </b-col>

              <b-col md="6" lg="6">
                <app-timeline>                                
                  <app-timeline-item variant="success" icon="MapIcon" style="margin-left:10px; padding:4px 20px">
                    <div @click="openFarmPolygon" class="sosty-pointer">
                      <h6><strong><u>Ver Polígono</u></strong></h6>                     
                    </div>
                  </app-timeline-item>
                  <app-timeline-item variant="warning" icon="SquareIcon" style="margin-left:10px; padding:4px 20px">
                    <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mt-0 mb-sm-0">
                      <h6 style="opacity:0.6">Hectáreas: {{carbonFarmInformation.sizeHa}} </h6>                     
                    </div>
                  </app-timeline-item>
                  <app-timeline-item variant="success" icon="CropIcon" style="margin-left:10px; padding:4px 20px">
                    <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mt-0 mb-sm-0">
                      <h6 style="opacity:0.6">Hectáreas Pastura: {{carbonFarmInformation.haGrass}} </h6>                     
                    </div>
                  </app-timeline-item>
                  <app-timeline-item variant="warning" icon="LayoutIcon" style="margin-left:10px; padding:4px 20px">
                    <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mt-0 mb-sm-0">
                      <h6 style="opacity:0.6">Hectáreas Bosques: {{carbonFarmInformation.haForest}} </h6>                     
                    </div>
                  </app-timeline-item>
                  <app-timeline-item variant="success" icon="FeatherIcon" style="margin-left:10px; padding:4px 20px">
                    <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mt-0 mb-sm-0">
                      <h6 style="opacity:0.6">Hectáreas Bosques Disponibles: {{carbonFarmInformation.haForestAvailable}} </h6>                     
                    </div>
                  </app-timeline-item>
                </app-timeline>                              
              </b-col>
            </b-row>
          </b-card>
        </b-col>                
      </b-row>

      <b-row>
        <b-col md="4" lg="4">
          <b-card >
            <b-card-body style="padding:0px">
              <b-row style="margin:0px;">
                <b-col xl="12" sm="12" style="padding-right:0px">
                  <b-media no-body style="text-align:center">
                    <b-alert show style="background: white !important">
                      <div b-tooltip.hover title="Ver Total Créditos Generados">
                        <b-media-aside style="margin-right:0px">
                          <b-avatar button @click="showTotalGeneratedCreditsModal" class="b-avatar badge-light-primary rounded-circle"  size="48" variant="light-primary">
                            <feather-icon size="24" icon="CreditCardIcon"/>
                          </b-avatar>
                        </b-media-aside>
                      </div>
                    </b-alert>
                    <b-media-body class="my-auto">
                      <h4 class="font-weight-bolder mb-0">
                        {{parseFloat(carbonFarmInformation.totalGeneratedCredits).toFixed(0).toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")}}
                      </h4>
                      <b-card-text class="font-small-3">
                        Total Créditos Generados
                      </b-card-text>
                    </b-media-body>
                  </b-media>
                </b-col>
              </b-row>
            </b-card-body>
          </b-card>
        </b-col>
      

        <b-col md="4" lg="4" >
          <b-card>
            <b-card-body style="padding:0px">
              <b-row style="margin:0px;">
                <b-col xl="12" sm="12" style="padding-right:0px">
                  <b-media no-body style="text-align:center">
                    <b-alert show style="background: white !important">
                      <div b-tooltip.hover title="Ver Total Créditos Vendidos" class="rounded-circle">
                        <b-media-aside style="margin-right:0px">
                          <b-avatar button @click="showTotalCreditsSoldModal" class="b-avatar badge-light-primary rounded-circle"  size="48" variant="light-primary">
                            <feather-icon size="24" icon="TrendingUpIcon"/>
                          </b-avatar>
                        </b-media-aside>
                      </div>
                    </b-alert>
                    <b-media-body class="my-auto">
                      <h4 class="font-weight-bolder mb-0">
                        {{parseFloat(carbonFarmInformation.totalCreditsSold).toFixed(0).toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")}}
                      </h4>
                      <b-card-text class="font-small-3 mb-0">
                        Total Créditos Vendidos
                      </b-card-text>
                    </b-media-body>
                  </b-media>
                </b-col>
              </b-row>
            </b-card-body>
          </b-card>                    
        </b-col>

        <b-col md="4" lg="4">
          <b-card>
            <b-card-body style="padding:0px">
              <b-row style="margin:0px;">
                <b-col xl="12" sm="12" style="padding-right:0px">
                  <b-media no-body style="text-align:center">
                    <b-alert show style="background: white !important">
                      <div b-tooltip.hover>
                        <b-media-aside style="margin-right:0px">
                          <b-avatar class="b-avatar badge-light-primary rounded-circle"  size="48" variant="light-primary">
                            <feather-icon size="24" icon="DollarSignIcon"/>
                          </b-avatar>
                        </b-media-aside>
                      </div>
                    </b-alert>
                    <b-media-body class="my-auto">
                      <h4 class="font-weight-bolder mb-0">
                        $ {{parseFloat(carbonFarmInformation.earnings).toFixed(0).toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")}}
                      </h4>
                      <b-card-text class="font-small-3 mb-0">
                        Ganancias
                      </b-card-text>
                    </b-media-body>
                  </b-media>
                </b-col>
              </b-row>
            </b-card-body>
          </b-card>
        </b-col>            
      </b-row>

        <b-row style="align-items:center">
          <b-col md="4" lg="4">      
            <b-card no-body style="height:300px" >
              <b-card-header>
                <b-card-title>
                  <h3 style="margin-bottom:40px">Créditos Generados</h3>                    
                </b-card-title>
              </b-card-header>
              <b-card-body class="pb-0" >              
                <vue-apex-charts
                  type="bar"
                  height="140"
                  :options="this.chartOptionsGeneratedCredits"
                  :series="this.chartSeriesGeneratedCredits"
                />
              </b-card-body>
            </b-card>
          </b-col>
          
          <b-col md="4" lg="4">      
            <b-card no-body style="height:300px" >
              <b-card-header>
                <b-card-title>
                  <h3 style="margin-bottom:40px">Dinero Generado</h3>                    
                </b-card-title>
              </b-card-header>
              <b-card-body class="pb-0" >              
                <vue-apex-charts
                  type="bar"
                  height="140"
                  :options="this.chartOptionsGeneratedEarnings"
                  :series="this.chartSeriesGeneratedEarnings"
                />
              </b-card-body>
            </b-card>
          </b-col>

          <b-col md="4" lg="4">    
            <b-card no-body style="height:300px" >
              <b-card-header>
                <b-card-title>
                  <h3 style="margin-bottom:-5px">Costo Crédito Promedio</h3>
                </b-card-title>
              </b-card-header>
              <b-card-body class="pb-0" >
                <vue-apex-charts
                height="170"
                :options="this.chartOptionsDonut"
                :series="this.seriesDonut"
                />
              </b-card-body>
            </b-card>
          </b-col>
        </b-row>

        <b-row style="align-items:center">
          <b-col>
              <b-card>
                <b-media no-body style="text-align:center">
                    <b-alert show style="background: white !important">
                      <div>
                        <b-media-aside style="margin-right:20px">
                          <b-avatar class="b-avatar badge-light-primary rounded-circle"  size="48" variant="light-primary">
                            <feather-icon size="24" icon="TrendingUpIcon"/>
                          </b-avatar>
                        </b-media-aside>
                      </div>
                    </b-alert>
                  <b-media-body class="my-auto">
                    <h4 class="font-weight-bolder mb-0">
                      {{parsefloatInDashBoard(carbonFarmInformation.pilar1)}}
                    </h4>
                    <b-card-text class="font-small-3">
                      Pilar 1: Productividad Ganadera
                    </b-card-text>
                  </b-media-body>
              </b-media>
            </b-card>
          </b-col>
          <b-col>
            <b-card>
              <b-media no-body style="text-align:center">
                <b-alert show style="background: white !important">
                  <div>
                    <b-media-aside style="margin-right:20px">
                      <b-avatar class="b-avatar badge-light-primary rounded-circle"  size="48" variant="light-primary">
                        <feather-icon size="24" icon="TrendingUpIcon"/>
                      </b-avatar>
                    </b-media-aside>
                  </div>
                </b-alert>
                <b-media-body class="my-auto">
                  <h4 class="font-weight-bolder mb-0">
                    {{parsefloatInDashBoard(carbonFarmInformation.pilar2)}}
                  </h4>
                  <b-card-text class="font-small-3">
                    Pilar 2: Socio-económico
                  </b-card-text>
                </b-media-body>
              </b-media>
          </b-card>
        </b-col>
          <b-col>
            <b-card>
              <b-media no-body style="text-align:center">
                <b-alert show style="background: white !important">
                  <div>
                    <b-media-aside style="margin-right:20px">
                      <b-avatar class="b-avatar badge-light-primary rounded-circle"  size="48" variant="light-primary">
                        <feather-icon size="24" icon="TrendingUpIcon"/>
                      </b-avatar>
                    </b-media-aside>
                  </div>
                </b-alert>
                <b-media-body class="my-auto">
                  <h4 class="font-weight-bolder mb-0">
                    {{parsefloatInDashBoard(carbonFarmInformation.pilar3)}}
                  </h4>
                  <b-card-text class="font-small-3">
                    Pilar 3: Regeneración Ambiental
                  </b-card-text>
                </b-media-body>
              </b-media>
          </b-card>
        </b-col>
          <b-col>
            <b-card>
              <b-media no-body style="text-align:center">
                <b-alert show style="background: white !important">
                  <div>
                    <b-media-aside style="margin-right:20px">
                      <b-avatar class="b-avatar badge-light-primary rounded-circle"  size="48" variant="light-primary">
                        <feather-icon size="24" icon="TrendingUpIcon"/>
                      </b-avatar>
                    </b-media-aside>
                  </div>
                </b-alert>
                <b-media-body class="my-auto">
                  <h4 class="font-weight-bolder mb-0">
                    {{parsefloatInDashBoard(carbonFarmInformation.pilar4)}}
                  </h4>
                  <b-card-text class="font-small-3">
                    Pilar 4: Dinámica de Paticipación
                  </b-card-text>
                </b-media-body>
              </b-media>
          </b-card>
        </b-col>
        </b-row>

        <b-row style="align-items:center">
          <b-col md="12" lg="12"> 
            <b-card >  
              <b-card-title>
                  <b-row >
                      <b-col xl="12" md="12" lg="12" class="mt-1">
                          <h3 >
                              Checklist
                          </h3>
                      </b-col>
                  </b-row>                    
              </b-card-title>
              <b-row v-if="carbonFarmChecklist.length <= 0" class="justify-content-center" style="text-align:center">
                <b-col xl="6" md="6" lg="6" >
                    <b-alert variant="primary" show>
                        <div class="alert-body">
                            <span><strong>No hay items en el Checklist para esta finca</strong></span>
                        </div>
                    </b-alert>
                </b-col>
              </b-row>          
              <b-row v-for="item in carbonFarmChecklist" :key="item.carbonFarmID">
                <b-alert v-if="item.isPending == true" class="ml-1" variant="danger" show>
                  <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
                    <p class="px-2">
                      <strong>
                        <b-badge v-if="item.isPending == true" pill variant="danger" style="font-size:12px" >Pendiente ({{TransformDateCheckList(item.createDate)}})</b-badge><a :href="item.link" target="_blank" class="btn btn-link fw-bold">- ..:: Link ::.. -</a>{{item.message}}
                      </strong>
                    </p>
                  </div>
                </b-alert>
                <b-alert v-else-if="item.isPending == false" class="ml-1" variant="success" show>
                  <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
                    <p class="px-2">
                      <strong>
                        <b-badge v-if="item.isPending == false" pill variant="success" style="font-size:12px" > Aprobado ({{TransformDateCheckList(item.createDate)}})</b-badge> - {{item.message}}
                      </strong>
                    </p>
                  </div>
                </b-alert>
              </b-row>              
            </b-card>
          </b-col>
        </b-row>

        <b-row class="justify-content-center">
          <b-col md="12" lg="12">
            <b-card>
              <b-card-title>
                <b-row>
                  <b-col xl="10" md="9" lg="10" class="mt-1">
                    <h3>
                      <feather-icon style="padding-bottom:3px" size="26" />
                      Monitoreo           
                    </h3> 
                  </b-col>
                  <b-col xl="2" md="3" lg="2" style="margin-top:5px;text-align:right">                         
                    <b-button v-if="this.userType == 'AdminCarbono' || userData.userID == carbonFarmInformation.carbonProducerID" variant="primary" block @click="createEvent">                        
                      <feather-icon icon="PlusIcon" class="mr-50"/>
                      <span class="align-middle">Crear evento</span>
                    </b-button>
                  </b-col> 
                </b-row>              
              </b-card-title>              

              <b-card-body>                
                  <b-row v-if="events.length <= 0" class="justify-content-center" style="text-align:center">
                    <b-col xl="6" md="6" lg="6" >
                        <b-alert variant="primary" show>
                            <div class="alert-body">
                                <span><strong>No hay eventos reportados para esta finca</strong></span>
                            </div>
                        </b-alert>
                    </b-col>
                  </b-row>            

                <app-timeline>
                  <app-timeline-item v-for="item in events" :key="item.carbonFarmEventID">
                    <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
                      <h6>{{item.eventType}}</h6>
                      <small class="text-muted">{{(item.eventDate).substring(0,10)}}</small>
                    </div>
                    <p class="mb-0"><pre style="white-space: pre-wrap;">{{ item.description }}</pre></p>                
                    <p  v-if="item.link"><a :href= item.link target="_blank">{{item.link}}</a></p>

                    <b-row class="mt-2">                  
                      <p v-if="item.eventVideo1Url || item.eventVideo2Url || item.eventVideo3Url">
                        <b-media-aside ref="video" style="margin-top:-15px; margin-right:-10px; margin-left:10px">
                          <b-avatar 
                            button @click="openModal(item)" size="48" variant="white">
                            <b-img :src= downImg height="auto" width="50px"/>
                          </b-avatar>
                        </b-media-aside>
                      </p>

                      <p v-if="item.checkImage1">
                        <b-media-aside style="margin-top:-15px; margin-right:-10px; margin-left:10px">
                            <b-avatar 
                              button @click="openModal(item)" size="48" variant="white" class="pull-up">
                              <b-img :src= item.picture1Url height="auto" width="50px"/>
                            </b-avatar>
                        </b-media-aside>
                      </p>

                      <p v-if="item.checkImage2">
                        <b-media-aside style="margin-top:-15px; margin-right:-10px">
                          <b-avatar button @click="openModal(item)" size="48" variant="white" class="pull-up">
                              <b-img :src= item.picture2Url height="auto" width="50px"/>
                            </b-avatar>
                        </b-media-aside>
                      </p>

                      <p v-if="item.checkImage3">
                        <b-media-aside style="margin-top:-15px; margin-right:-10px">
                          <b-avatar button @click="openModal(item)" size="48" variant="white" class="pull-up">
                              <b-img :src= item.picture3Url height="auto" width="50px"/>
                            </b-avatar>
                        </b-media-aside>
                      </p>

                      <p v-if="item.checkImage4">
                        <b-media-aside style="margin-top:-15px; margin-right:-10px">
                          <b-avatar button @click="openModal(item)" size="48" variant="white" class="pull-up">
                              <b-img :src= item.picture4Url height="auto" width="50px" />
                            </b-avatar>
                        </b-media-aside>
                      </p>

                      <p v-if="item.checkImage5">
                        <b-media-aside style="margin-top:-15px; margin-right:-10px">
                          <b-avatar button @click="openModal(item)" size="48" variant="white" class="pull-up">
                              <b-img :src= item.picture5Url height="auto" width="50px" />
                            </b-avatar>
                        </b-media-aside>
                      </p>

                      <p v-if="item.file1">
                        <b-media-aside style="margin-top:-15px; margin-right:1px; margin-left:25px">
                            <b-avatar button @click="openDocument(item.file1)" size="48" variant="white" class="pull-up">
                              <b-img :src="require('@/assets/images/icons/doc.png')" height="auto" width="20"/>
                            </b-avatar>
                        </b-media-aside>
                      </p>

                      <p v-if="item.file2">
                        <b-media-aside style="margin-top:-15px; margin-right:1px ; margin-left:10px">
                            <b-avatar button @click="openDocument(item.file2)" size="48" variant="white" class="pull-up">
                              <b-img :src="require('@/assets/images/icons/doc.png')" height="auto" width="20"/>
                            </b-avatar>
                        </b-media-aside>
                      </p>

                      <p v-if="item.file3">
                        <b-media-aside style="margin-top:-15px; margin-right:1px ; margin-left:10px">
                            <b-avatar button @click="openDocument(item.file3)" size="48" variant="white" class="pull-up">
                              <b-img :src="require('@/assets/images/icons/doc.png')" height="auto" width="20"/>
                            </b-avatar>
                        </b-media-aside>
                      </p>
                      <p>
                        <b-media-aside style="margin-top:-15px; margin-right:1px ; margin-left:10px">
                            <b-avatar button @click="downloadFilesZip(item)" size="48" variant="white" class="pull-up">
                              <b-img src="@/assets/images/icons/file-icons/downloadIcon.png" height="auto" width="auto"></b-img>
                            </b-avatar>
                        </b-media-aside>
                      </p>
                    </b-row>
                  </app-timeline-item>
                </app-timeline>
              </b-card-body>

              <b-modal size="sm" ref="create-new-event" id="create-new-event" centered hide-footer title="Digita la siguiente información">            
                <b-card-text>                 
                  <b-form>
                    <b-row>
                      <b-col md="12" xl="12" class="mb-0">
                        <b-form-group label="Evento" label-for="basicInput">
                          <b-form-select v-model="eventType" 
                            :options="optionsEvents">
                            <b-form-select-option :value="null"> Escoja un Evento...</b-form-select-option>
                          </b-form-select>
                        </b-form-group>                       
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col md="12" xl="12" class="mb-0">
                        <label for="example-datepicker">Descripción</label>
                        <b-form-textarea v-model="description" id="textarea-default" rows="5"/>
                      </b-col>   
                    </b-row>
                    <b-row>
                      <b-col md="12" xl="12" class="mb-0">
                        <label for="example-datepicker">Fecha Evento</label>
                        <b-form-datepicker v-model="eventDate" id="example-datepicker1" class="mb-1"/>
                      </b-col>
                    </b-row>

                    <b-row class="mt-1">
                      <b-col>
                        <h6>
                          Link
                        </h6>
                      </b-col>                        
                    </b-row>

                    <b-row>
                      <b-col md="12" xl="12" class="mb-1">                            
                        <b-form-input v-model="link" placeholder="Copia y pega el link de youtube"/>                            
                      </b-col>
                    </b-row>

                    <b-row class="mt-2">
                      <b-col>
                        <h6>
                          Carga de Videos
                        </h6>
                      </b-col>                        
                    </b-row>

                    <b-row>
                      <b-col md="6" xl="6" class="mb-0">
                        <b-form-group label="Video 1" label-for="basicInput">
                          <b-form-file placeholder="Seleccione" v-model="eventVideo1File" />
                          <b-card-text @click="openVideo(eventSelected.eventVideo1Url)" v-if="eventSelected.eventVideo1Url" class="cursor-pointer sosty-font-smaller" style="color:#008D36">Ver<feather-icon icon="ArrowDownCircleIcon" /></b-card-text>
                        </b-form-group>
                      </b-col>
                        <b-col md="6" xl="6" class="mb-0">
                          <b-form-group label="Video 2" label-for="basicInput">
                            <b-form-file placeholder="Seleccione" v-model="eventVideo2File" />
                            <b-card-text @click="openVideo(eventSelected.eventVideo2Url)" v-if="eventSelected.eventVideo2Url" class="cursor-pointer sosty-font-smaller" style="color:#008D36">Ver<feather-icon icon="ArrowDownCircleIcon" /></b-card-text>
                          </b-form-group>
                        </b-col>
                    </b-row>

                    <b-row>
                      <b-col md="6" xl="6" class="mb-0">
                        <b-form-group label="Video 3" label-for="basicInput">
                          <b-form-file placeholder="Seleccione" v-model="eventVideo3File" />
                          <b-card-text @click="openVideo(eventSelected.eventVideo3Url)" v-if="eventSelected.eventVideo3Url" class="cursor-pointer sosty-font-smaller" style="color:#008D36">Ver<feather-icon icon="ArrowDownCircleIcon" /></b-card-text>
                        </b-form-group>
                      </b-col>
                    </b-row>
                    
                    <b-row class="mt-2">
                      <b-col>
                        <h6>
                          Carga de Imágenes
                        </h6>
                      </b-col>                        
                    </b-row>

                    <b-row>
                      <b-col md="6" xl="6" class="mb-0">
                        <b-form-group label="Imagen 1" label-for="basicInput">
                          <b-form-file placeholder="Seleccione" v-model="picture1File" accept=".jpg,.png"/>
                          <b-card-text @click="openDocument(eventSelected.picture1Url)" v-if="eventSelected.picture1Url" class="cursor-pointer sosty-font-smaller">abrir/descargar <feather-icon icon="ArrowDownCircleIcon" /></b-card-text>
                        </b-form-group>
                      </b-col>

                      <b-col md="6" xl="6" class="mb-0">
                        <b-form-group label="Imagen 2" label-for="basicInput">
                          <b-form-file placeholder="Seleccione" v-model="picture2File" accept=".jpg,.png" />
                          <b-card-text @click="openDocument(eventSelected.picture2Url)" v-if="eventSelected.picture2Url" class="cursor-pointer sosty-font-smaller">abrir/descargar <feather-icon icon="ArrowDownCircleIcon" /></b-card-text>
                        </b-form-group>
                      </b-col>
                    </b-row>

                    <b-row>
                      <b-col md="6" xl="6" class="mb-0">
                        <b-form-group label="Imagen 3" label-for="basicInput">
                          <b-form-file placeholder="Seleccione" v-model="picture3File" accept=".jpg,.png" />
                          <b-card-text @click="openDocument(eventSelected.picture3Url)" v-if="eventSelected.picture3Url" class="cursor-pointer sosty-font-smaller">abrir/descargar <feather-icon icon="ArrowDownCircleIcon" /></b-card-text>
                        </b-form-group>
                      </b-col>

                      <b-col md="6" xl="6" class="mb-0">
                        <b-form-group label="Imagen 4" label-for="basicInput">
                          <b-form-file placeholder="Seleccione" v-model="picture4File" accept=".jpg,.png"/>
                          <b-card-text @click="openDocument(eventSelected.picture4Url)" v-if="eventSelected.picture4Url" class="cursor-pointer sosty-font-smaller">abrir/descargar <feather-icon icon="ArrowDownCircleIcon" /></b-card-text>
                        </b-form-group>
                      </b-col>
                    </b-row>

                    <b-row>
                      <b-col md="6" xl="6" class="mb-0">
                        <b-form-group label="Imagen 5" label-for="basicInput">
                          <b-form-file placeholder="Seleccione" v-model="picture5File" accept=".jpg,.png" />
                          <b-card-text @click="openDocument(eventSelected.picture5Url)" v-if="eventSelected.picture5Url" class="cursor-pointer sosty-font-smaller">abrir/descargar <feather-icon icon="ArrowDownCircleIcon" /></b-card-text>
                        </b-form-group>
                      </b-col>
                    </b-row>

                    <b-row class="mt-2">
                      <b-col>
                        <h6>
                          Carga de Archivos
                        </h6>
                      </b-col>                        
                    </b-row>

                    <b-row>
                      <b-col md="6" xl="6" class="mb-0">
                        <b-form-group label="Archivo 1" label-for="basicInput">
                          <b-form-file placeholder="Seleccione" v-model="eventFile1File" accept=".pdf"/>
                          <b-card-text @click="openDocument(eventSelected.eventFile1Url)" v-if="eventSelected.eventFile1Url" class="cursor-pointer sosty-font-smaller">abrir/descargar<feather-icon icon="ArrowDownCircleIcon" /></b-card-text>
                        </b-form-group>
                      </b-col>

                      <b-col md="6" xl="6" class="mb-0">
                        <b-form-group label="Archivo 2" label-for="basicInput">
                          <b-form-file placeholder="Seleccione" v-model="eventFile2File" accept=".pdf"/>
                          <b-card-text @click="openDocument(eventSelected.eventFile2Url)" v-if="eventSelected.eventFile2Url" class="cursor-pointer sosty-font-smaller">abrir/descargar<feather-icon icon="ArrowDownCircleIcon" /></b-card-text>
                        </b-form-group>
                      </b-col>
                    </b-row>

                    <b-row>
                      <b-col md="6" xl="6" class="mb-0">
                        <b-form-group label="Archivo 3" label-for="basicInput">
                          <b-form-file placeholder="Seleccione" v-model="eventFile3File" accept=".pdf"/>
                          <b-card-text @click="openDocument(eventSelected.eventFile3Url)" v-if="eventSelected.eventFile3Url" class="cursor-pointer sosty-font-smaller">abrir/descargar<feather-icon icon="ArrowDownCircleIcon" /></b-card-text>
                        </b-form-group>
                      </b-col>
                    </b-row>

                    <b-row  class="mt-1 justify-content-end">
                      <b-col v-if="!processing" class="mt-2" md="4" lg="4">
                        <b-button variant="primary" @click="saveCarbonFarmEvent" block>                                                            
                          Guardar
                        </b-button>
                      </b-col>
                      <b-col v-if="processing" class="mt-2" md="4" lg="4">
                        <b-button variant="primary" disabled block>
                          <b-spinner small label="Cargando..." />
                        </b-button>
                      </b-col>
                    </b-row>                    
                  </b-form>                
                </b-card-text>        
              </b-modal>
            </b-card>
          </b-col>
        </b-row>

        <b-modal ref="imagesModal" id="create-new-event" centered hide-footer title="Galeria de Imagenes" >
          <b-col md="12" lg="12" >        
            <b-carousel id="carousel-interval" controls indicators :interval="0">
              
              <b-carousel-slide v-if="event.eventVideo1Url">
                <template #img>
                  <iframe :src="eventVideo1Url" class="sosty-project-details-video" frameborder="0" style="max-height:700px" allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>                
                </template>
              </b-carousel-slide>

              <b-carousel-slide v-if="event.eventVideo2Url">
                <template #img>
                  <iframe :src="eventVideo2Url" class="sosty-project-details-video" frameborder="0" style="max-height:700px" allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </template>
              </b-carousel-slide>

              <b-carousel-slide v-if="event.eventVideo3Url">
                <template #img>
                  <iframe :src="eventVideo3Url" class="sosty-project-details-video" frameborder="0" style="max-height:700px" allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </template>
              </b-carousel-slide>

              <b-carousel-slide v-if="this.checkImage1" :img-src="this.checkImage1"  fluid alt="Responsive image" style="max-height:100%;max-width:100%" />
              <b-carousel-slide v-if="this.checkImage2" :img-src="this.checkImage2"  fluid alt="Responsive image" style="max-height:100%;max-width:100%" />
              <b-carousel-slide v-if="this.checkImage3" :img-src="this.checkImage3"  fluid alt="Responsive image" style="max-height:100%;max-width:100%" />
              <b-carousel-slide v-if="this.checkImage4" :img-src="this.checkImage4"  fluid alt="Responsive image" style="max-height:100%;max-width:100%" />
              <b-carousel-slide v-if="this.checkImage5" :img-src="this.checkImage5"  fluid alt="Responsive image" style="max-height:100%;max-width:100%" />
            </b-carousel>        
          </b-col>
        </b-modal>

        <b-modal size="lg" ref="view-total-generated-credits-modal" title="Total Créditos Generados" centered hide-footer>
          <view-total-generated-credits-component
            :carbonFarmID=this.carbonFarmInformation.carbonFarmID></view-total-generated-credits-component>
          <b-row class="justify-content-end">
            <b-col class="mt-1 mb-1" md="4" lg="4">
              <b-button variant="primary" @click="hideTotalGeneratedCreditsModal" block>
                Cerrar
              </b-button>
            </b-col>
          </b-row>
        </b-modal>

        <b-modal size="lg" ref="view-total-credits-sold-modal" title="Total Créditos Vendidos" centered hide-footer>
          <view-total-credits-sold-component
            :carbonFarmID=this.carbonFarmInformation.carbonFarmID></view-total-credits-sold-component>
          <b-row class="justify-content-end">
            <b-col class="mt-1 mb-1" md="4" lg="4">
              <b-button variant="primary" @click="hideTotalCreditsSoldModal" block>
                Cerrar
              </b-button>
            </b-col>
          </b-row>
        </b-modal>

    </div>  
  </template>

  <script>
  
  import { getUserData } from '@/auth/utils'
  import { BCard, BBadge, BCardText, BButton, BRow, BCol, BImg, BCardBody, BCardTitle, BCardSubTitle, BLink, BProgress, BProgressBar, BMedia, BAvatar, BMediaAside, BMediaBody, BEmbed, 
  BCardHeader, BCarousel, BCarouselSlide, BModal, BFormGroup, BFormInput, BInputGroupAppend, BInputGroup, BAlert, BTooltip, BAvatarGroup, VBTooltip, BTable, BCalendar, BFormDatepicker,
  BFormCheckbox, BForm, BSpinner, BFormSelect, BFormSelectOption, BFormFile, BFormTextarea } from 'bootstrap-vue'
  import StatisticCardHorizontal from '@core/components/statistics-cards/StatisticCardHorizontal.vue'
  import StatisticCardVertical from '@core/components/statistics-cards/StatisticCardVertical.vue'
  import Cleave from 'vue-cleave-component'
  import VueApexCharts from 'vue-apexcharts'
  import { $themeColors } from '@themeConfig'
  import BCardCode from '@/@core/components/b-card-code/BCardCode.vue'
  import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
  import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'  
  import PhasesRowTimelineCarbonFarm from '@core/components/PhasesRowTimelineCarbonFarm'
  import axios from '@axios'
  import axiosIns from '@/libs/axios'
  import moment from 'moment'
  import ViewTotalGeneratedCreditsComponent from '@/@core/components/ViewTotalGeneratedCreditsComponent.vue'
  import ViewTotalCreditsSoldComponent from '@core/components/ViewTotalCreditsSoldComponent'

  const $averageCreditValueStrokeColor1 = '#2196F3'
  const $averageCreditValueStrokeColor2 = '#009688'
  const $averageCreditValueStrokeColor3 = '#FF9800'
  const $averageCreditValueStrokeColor4 = '#CDDC39'
  const $averageCreditValueStrokeColor5 = '#F44336'
  
  export default {
    components: {
        AppTimeline,
        AppTimelineItem,
        BCard,
        BCardText,    
        BAvatarGroup,
        BButton,
        BCardBody,
        BCardTitle,
        BCardSubTitle,
        BCardHeader,
        BRow,
        BCol,
        BLink,
        BImg,
        BProgress,
        BProgressBar,
        BTable,
        StatisticCardHorizontal,
        StatisticCardVertical,
        BMedia,
        BAvatar,
        BMediaAside,
        BMediaBody,
        BCardCode,
        BEmbed,
        BCarousel, 
        BCarouselSlide,
        BModal,
        BFormGroup, 
        BFormInput,
        BFormSelectOption, 
        BInputGroupAppend, 
        BInputGroup,
        Cleave,
        VueApexCharts,
        BAlert,
        BTooltip,
        BBadge,
        BCalendar,
        BFormDatepicker,
        BFormCheckbox,
        BForm,
        BSpinner,
        BFormSelect,
        BFormFile,
        BFormTextarea,        
        PhasesRowTimelineCarbonFarm,
        ViewTotalGeneratedCreditsComponent,
        ViewTotalCreditsSoldComponent
    },
    props: {
      data: {
        type: Object,
        default: () => {},
      },
    },
    directives: {
      'b-tooltip': VBTooltip,
    },

    data() {
      return {
        axiosIns: axiosIns,
        processing: false,
        carbonFarmChecklist:{},
        carbonFarms:{},
        carbonFarmID: null,
        farmCode: null,
        carbonProducerID: null,
        userType: null,
        userData: null,
        carbonFarmInformation:{}, 
        events:[],
        event: {},        
        checkEventVideoUrl1: null,
        checkEventVideoUrl2: null,
        checkEventVideoUrl3: null,
        downImg: null,
        eventVideo1Url: null,
        eventVideo2Url: null,
        eventVideo3Url: null,
        videosList: null,
        checkImage1: null,
        checkImage2: null,
        checkImage3: null,
        checkImage4: null,
        checkImage5: null,
        file1: null,
        file2: null,
        file3: null,
        eventDate: null,
        description: null,
        fromDate: null,
        initDate: null,
        endDate: null,
        isPending: null,
        message: null,
        eventSelected: {},
        latitude: null,
        longitude: null,
        farmPolygon: null,
        eventType: null,
        picture1Url: null,
        picture2Url: null,
        picture3Url: null,
        picture4Url: null,
        picture5Url: null, 
        eventFile1Url: null,
        eventFile2Url: null,
        eventFile3Url: null,
        payload:{},
        carbonFarmEvent:{},
        carbonFarmEventID: null,
        picture1File: null,
        picture2File: null,
        picture3File: null,
        picture4File: null,
        picture5File: null,
        eventFile1File: null,
        eventFile2File: null,
        eventFile3File: null,
        eventVideo1Url: null,
        eventVideo2Url: null,
        eventVideo3Url: null,
        link: null,
        eventVideo1File: null,
        eventVideo2File: null,
        eventVideo3File: null,
        videoUrl: null,
        url: null,
        optionsEvents : ['Actualización General', 'Cargue Documentos',
                          'Venta de Ganado', 'Traslado de Animales',
                          'Rotación de Pasturas', 'Quema/Incendio',
                          'Conflicto de Tierra', 'Aforo',
                          'Backup', 'Avistamiento Biodiversidad (Macrofauna)',
                          'Avistamiento Biodiversidad (Microfauna)', 
                          'Rotación Ganado', 'Protección Bosques',
                          'Mejoramiento Infraestructura', 'Otro...' ],
        sideImg: require('@/assets/images/pages/default-dashboard.png'),
        
        
        chartSeriesGeneratedEarnings:[{
        name: 'Dinero Generado',
        data: []
        }],
        chartOptionsGeneratedEarnings: {
          chart: {
            toolbar: { show: false },
            zoom: { enabled: false },
            type: 'bar',
          },
          columnWidth: '20%',
          dataLabels:{
            enabled: false
          },
          colors: ['#008D36','#FF9F43' ],
          xaxis: {                             
            labels: { 
              show: true,
              rotate: -30,
              rotateAlways: true,  
            },
            axisTicks: {              
              show: true,              
            },  
            categories: [],
            tickPlacement: 'on',
          },
          yaxis: {
            scaleShowLabels: false,
            tickAmount: 3,
            labels: {
              style: {
                colors: '#b9b9c3',
                fontSize: '1rem',
              },
              formatter(val) {
                return new Intl.NumberFormat('es-CO',{style: 'currency', currency: 'COP'}).format(val)
              },
            },
            },
          grid: {
            borderColor: '#e7eef7',
            padding: {
              top: -20,
              bottom: -10,
              left: 20,
            },
          },
          tooltip: {
            x: { show: false },            
          },
        },

        seriesDonut: [],
        chartOptionsDonut: {
          chart: {
              type: 'donut',
              toolbar: {
              show: false,
              },
          },
          dataLabels: {
              enabled: true,
          },
          legend: { show: true },
          labels: ['Certificación',
                    'Auditoría',
                    'Transaccional',
                    'Administrativo',
                    'Monitoreo'],
          stroke: { width: 0 },
          colors: [$averageCreditValueStrokeColor1, $averageCreditValueStrokeColor2, $averageCreditValueStrokeColor3, $averageCreditValueStrokeColor4, $averageCreditValueStrokeColor5],
          grid: {
              padding: {
              right: -20,
              bottom: -8,
              left: -20,
              },
          },
          plotOptions: {
            pie: {             
              donut: {
                  size: '60%',                        
                  labels: {
                    show: true,
                    name: {
                        offsetY: 15,
                    },
                    value: {
                      show: true,
                        offsetY: -15,
                        formatter(val) {
                        // eslint-disable-next-line radix                        
                        return '$ ' + parseInt(val)
                        },
                    },
                    total: {
                      show: true,
                      offsetY: 15,
                      label: 'Pesos',
                      formatter: function(w) {
                        const total = w.globals.seriesTotals.reduce((a, b) => {
                          return a + b;
                        }, 0);
                        return '$ ' + parseFloat(total).toFixed(0)
                      },
                    },
                  },                    
              },
            },
          },
          responsive: [
            {
              breakpoint: 1325,
              options: {
                chart: {
                  height: 100,
                },
              },
            },
            {
              breakpoint: 1200,
              options: {
                chart: {
                  height: 120,
                },
              },
            },
            {
              breakpoint: 1045,
              options: {
                chart: {
                  height: 100,
                },
              },
            },
            {
              breakpoint: 992,
              options: {
                chart: {
                  height: 120,
                },
              },
            },
          ],
        },
        
        chartOptionsGeneratedCredits:{
          chart:{
            toolbar: { show: false },
            zoom: { enabled: false },
            type: 'bar'
          },
          dataLabels:{
            enabled: false
          },
          colors:['#008D36','#FF9F43'],
          xaxis: {
            labels: { 
              show: true,
              rotate: -30,
              rotateAlways: true,  
            },
            axisTicks: {              
              show: true,              
            },
            categories: [],
            tickPlacement: 'on',
          },
          yaxis: {
            scaleShowLabels: false,
            tickAmount: 3,
            labels: {
              style: {
                colors: '#b9b9c3',
                fontSize: '1rem',
              },
              formatter(val) {
                return val
              },
            },
          },
          grid: {
            borderColor: '#e7eef7',
            padding: {
              top: -20,
              bottom: -10,
              left: 20,
            },
          },
          tooltip: {
            x: { show: false },            
          },
        },
        chartSeriesGeneratedCredits:[{
          name: 'Creditos Generados',
          data: []
        }],
      }
    },

    created() {
        
        if (this.$route != null && this.$route.query != null && this.$route.query.farmCode != null) {
          this.userData = getUserData();
          this.userType = this.userData.userType;
          this.farmCode = this.$route.query.farmCode;
          this.carbonProducerID = null
          this.getCarbonFarmProgressInformation();
          this.getCarbonFarmChecklistByCarbonFarmID();
        };        
    },

    watch:{
      carbonFarmEventID: function(val) { this.carbonFarmEventID = val; },
      picture1File: function(val) { this.picture1File = val; this.uploadFile(val, 'picture1File'); },
      picture2File: function(val) { this.picture2File = val; this.uploadFile(val, 'picture2File'); },
      picture3File: function(val) { this.picture3File = val; this.uploadFile(val, 'picture3File'); },
      picture4File: function(val) { this.picture4File = val; this.uploadFile(val, 'picture4File'); },
      picture5File: function(val) { this.picture5File = val; this.uploadFile(val, 'picture5File'); },
      eventFile1File: function(val) { this.eventFile1File = val; this.uploadFile(val, 'eventFile1File'); },
      eventFile2File: function(val) { this.eventFile2File = val; this.uploadFile(val, 'eventFile2File'); },
      eventFile3File: function(val) { this.eventFile3File = val; this.uploadFile(val, 'eventFile3File'); },
      eventVideo1File: function(val) {this.eventVideo1File = val; this.uploadVideo(val, 'eventVideo1File')},
      eventVideo2File: function(val) {this.eventVideo2File = val; this.uploadVideo(val, 'eventVideo2File')},
      eventVideo3File: function(val) {this.eventVideo3File = val; this.uploadVideo(val, 'eventVideo3File')}
    },  

    computed: {
      imgUrl() {
        return this.sideImg
      },
      
    },
 
    methods:{
        getCarbonFarmProgressInformation(){
            axios.get('/CarbonFarm/GetCarbonFarmProgressInformation', { params: { farmCode: this.farmCode }})        
            .then(response => {
            this.processing = false;
            console.log('DATA', response.data);
            this.carbonFarmInformation = response.data;            
            this.latitude = this.carbonFarmInformation.latitude;
            this.longitude = this.carbonFarmInformation.longitude;
            this.farmPolygon = this.carbonFarmInformation.farmPolygon;
            this.events = this.carbonFarmInformation.events; 
            console.log(this.events)
            this.calculateEvents();  
            this.calculateChartPointsGeneratedCredits();
            this.calculateChartPointsGeneratedEarnings();
            this.calculatePieChartData();
            })
        },

        getCarbonFarmChecklistByCarbonFarmID() {
          axios.get('/CarbonFarmChecklist/GetCarbonFarmChecklistByCarbonFarmCode', {
              params: {
                farmCode: this.farmCode,
              }
          })
          .then(response => {
              this.carbonFarmChecklist = response.data;   
          })
        },

        openDocument(fileUrl) {
          window.open(fileUrl, '_blank');
        },

        calculateEvents(){
        this.downImg = require('@/assets/images/pages/sostyVideo.jpg')
        this.events.forEach(item => {                      
            item.checkImage1 = item.picture1Url            
            item.checkImage2 = item.picture2Url
            item.checkImage3 = item.picture3Url
            item.checkImage4 = item.picture4Url
            item.checkImage5 = item.picture5Url
            item.file1 = item.eventFile1Url
            item.file2 = item.eventFile2Url
            item.file3 = item.eventFile3Url  
            item.checkEventVideoUrl1 = item.eventVideo1Url
            item.checkEventVideoUrl2 = item.eventVideo2Url
            item.checkEventVideoUrl3 = item.eventVideo3Url     
        }) 
        },

        calculatePieChartData(){          
          var donutValues = [];
          donutValues.push(this.carbonFarmInformation.certificationCostPercentage);
          donutValues.push(this.carbonFarmInformation.auditingCostPercentage);
          donutValues.push(this.carbonFarmInformation.transactionCostPercentage);
          donutValues.push(this.carbonFarmInformation.managementCostPercentage);
          donutValues.push(this.carbonFarmInformation.monitoringCostPercentage);
          this.seriesDonut = donutValues;
          console.log('Valores Serie', this.seriesDonut)
        },

        calculateChartPointsGeneratedCredits() {
          const newData = this.carbonFarmInformation.generatedCreditsList;
          
          const newXaxis = this.carbonFarmInformation.generatedCreditsDatesList;
          
          this.chartOptionsGeneratedCredits = {...this.chartOptionsGeneratedCredits, ...{xaxis:{categories: newXaxis}}};
          this.chartSeriesGeneratedCredits = [{
            data: newData
          }];
        },

        calculateChartPointsGeneratedEarnings() {
          const newData = this.carbonFarmInformation.carbonSalesList;
          
          const newXaxis = this.carbonFarmInformation.carbonSalesDatesList;
          
          this.chartOptionsGeneratedEarnings = {...this.chartOptionsGeneratedEarnings, ...{xaxis:{categories: newXaxis}}};
          this.chartSeriesGeneratedEarnings = [{
            data: newData
          }];
        },

        openFarmLocationInMap() {
          const latitude = this.latitude;
          const longitude = this.longitude;
          const mapsUrl = "https://www.google.com/maps?q=" + latitude + "," + longitude;
          window.open(mapsUrl, '_blank');
        },

        openFarmPolygon() {
          const urlFarmPolygon = this.farmPolygon      
            window.open(urlFarmPolygon, '_blank');
        },
        
        createEvent(){               
          if(this.eventSelected.carbonFarmEventID != null || this.eventSelected.carbonFarmEventID != undefined){                
            this.carbonFarmID = null,
            this.eventType = null,
            this.description = null,
            this.eventDate = null,
            this.picture1Url = null,
            this.picture2Url = null,
            this.picture3Url = null,
            this.picture4Url = null,
            this.picture5Url = null,
            this.eventFile1Url = null,
            this.eventFile2Url = null,
            this.eventFile3Url = null,
            this.eventVideo1Url = null,
            this.eventVideo2Url = null,
            this.eventVideo3Url = null,
            this.link = null
          }
          this.showEventModal()
        },        
        
        saveCarbonFarmEvent(){   
          this.processing = true;         
          if(!this.eventType || !this.eventDate || !this.description){
            this.$bvToast.toast("Debes diligenciar Evento, Fecha de Evento y Descripción", { title: 'Error', variant: 'danger', solid: true, })
            this.processing = false;
          } else {                    
            this.eventSelected.carbonFarmID = this.carbonFarmInformation.carbonFarmID  
            this.eventSelected.eventType = this.eventType,
            this.eventSelected.description = this.description,
            this.eventSelected.eventDate = this.eventDate,
            this.eventSelected.link = this.link
            var payload = {
              eventSelected: this.eventSelected
            }                
        
            axios.post('/CarbonFarm/SaveCarbonFarmEvent', payload)
            .then(response => {
              this.processing = false;
              if(response.data) {     
                  this.$bvToast.toast("Información guardada de forma exitosa", { title: 'Ok', variant: 'success', solid: true, });  
                  this.carbonFarms = response.data;   
                  this.hideEventModal();       
              } else {
                  this.$bvToast.toast("Ocurrio un problema", { title: 'Error', variant: 'danger', solid: true, })     
              }                    
              window.location.reload()
            })            
          }
        },

        showEventModal() {     
          this.processing = false,     
          this.$refs['create-new-event'].show()         
        },

        hideEventModal(){
          this.$refs['create-new-event'].hide()                  
        },

        uploadFile(fileToUpload, fileType) {
          console.log({
            fileToUpload, fileType
          })
          this.processing = true;               
          let formData = new FormData();
          formData.append('file', fileToUpload);
          axios.post('/File/UploadFile', formData, { headers: { 'Content-Type': 'multipart/form-data' } })
          .then(response => {
          this.processing = false;
          if(response.data) {                
            this.setFileUrl(fileType, response.data);
            this.$bvToast.toast("Documento subido de forma correcta", { title: 'Ok', variant: 'success', solid: true, });            
            }
            else {
            this.$bvToast.toast("Ocurrio un problema", { title: 'Email', variant: 'danger', solid: true, })     
            }
          })
          .catch(error => {
          this.processing = false;
            if(error.response){
            this.$bvToast.toast(error.response.data, { title: 'Error', variant: 'danger', solid: true, })
            }
            else {
            this.$bvToast.toast(JSON.stringify(error), { title: 'Error', variant: 'danger', solid: true, })
            }
          })
        },

        setFileUrl(fileType, fileUrl) {            
          switch(fileType) {
            case 'picture1File':
                this.eventSelected.picture1Url = fileUrl;
                break;
            case 'picture2File':
                this.eventSelected.picture2Url = fileUrl;
                break;
            case 'picture3File':
                this.eventSelected.picture3Url = fileUrl;
                break;
            case 'picture4File':
                this.eventSelected.picture4Url = fileUrl;
                break;
            case 'picture5File':
                this.eventSelected.picture5Url = fileUrl;
                break;
            case 'eventFile1File':
                this.eventSelected.eventFile1Url = fileUrl;
                break;
            case 'eventFile2File':
                this.eventSelected.eventFile2Url = fileUrl;
                break;
            case 'eventFile3File':
                this.eventSelected.eventFile3Url = fileUrl;
                break;
            case 'eventVideo1File':
                this.eventSelected.eventVideo1Url = fileUrl;
                break;
            case 'eventVideo2File':
                this.eventSelected.eventVideo2Url = fileUrl;
                break;
            case 'eventVideo3File':
                this.eventSelected.eventVideo3Url = fileUrl;
                break;                    
            }
        },        
        
        uploadVideo(fileToUpload, fileType) {
          this.processing = true;               
          let formData = new FormData();            
          formData.append('file', fileToUpload);
          axios.post('/File/UploadVideo', formData, { headers: { 'Content-Type': 'multipart/form-data' } })
          .then(response => {
          this.processing = false;
          if(response.data) {                
            this.setFileUrl(fileType, response.data);
            this.$bvToast.toast("Video subido de forma correcta", { title: 'Ok', variant: 'success', solid: true, });
            }
          else {
            this.$bvToast.toast("Ocurrio un problema", { title: 'Email', variant: 'danger', solid: true, })     
          }
          })
          .catch(error => {
          this.processing = false;
            if(error.response){
            this.$bvToast.toast(error.response.data, { title: 'Error', variant: 'danger', solid: true, })
            }
            else {
            this.$bvToast.toast(JSON.stringify(error), { title: 'Error', variant: 'danger', solid: true, })
            }
          })
        },

        openVideo(url){            
          this.url = url
          window.open(this.url, '_blank');
        },

        openDocument(fileUrl) {
        window.open(fileUrl, '_blank');
        },

        openModal(item){  
          this.event = item
          this.eventVideo1Url = this.event.eventVideo1Url;
          this.eventVideo2Url = this.event.eventVideo2Url;
          this.eventVideo3Url = this.event.eventVideo3Url;      

          this.checkImage1 = (this.event.picture1Url)
          this.checkImage2 = (this.event.picture2Url)
          this.checkImage3 = (this.event.picture3Url)
          this.checkImage4 = (this.event.picture4Url)
          this.checkImage5 = (this.event.picture5Url)

          this.$refs['imagesModal'].show()          
        },
        
        showTotalGeneratedCreditsModal() {
          this.$refs['view-total-generated-credits-modal'].show()
        },

        hideTotalGeneratedCreditsModal() {
          this.$refs['view-total-generated-credits-modal'].hide()
        },

        showTotalCreditsSoldModal() {
          this.$refs['view-total-credits-sold-modal'].show()
        },

        hideTotalCreditsSoldModal() {
          this.$refs['view-total-credits-sold-modal'].hide()
        },
        TransformDate(date){
          if (date == null) {
            return "--/--/----"}
          else 
          {return moment(date).format('DD/MM/YYYY')}        
        },
        CheckTimeline(date) {
          if(date == null){
            return "item-unchecked mx-auto my-1"}
          else{
            return "item-checked mx-auto my-1"
          }
        },
        TransformDateCheckList(date){
          if (date == null) {
            return "--/--/----"}
          else 
          {return moment(date).format('DD/MM/YYYY')}        
        },
        downloadFilesZip(item){
          console.log(item.carbonFarmEventID)
          window.open(this.axiosIns.sostyApiUrl + 'CarbonFarm/DownloadFilesCarbonFarm?carbonFarmEventID=' + item.carbonFarmEventID,'blank');
        },
        parsefloatInDashBoard(floatNumber){
          if (floatNumber == null) {
            return "--"
          }
          else {
            return parseFloat(floatNumber).toFixed(1).toString();
          }
        },
     },
  }
  </script>
  
  <style lang="scss">
  @import '@core/scss/vue/pages/page-misc.scss';
  .imgBoxes {
    border: 1px solid #006769
  }
  </style>